<template>
	<v-container fluid>
		<RegisterForm color="transparent" :dark="false" />
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('auth.register')
		}
	},
	components: {
		RegisterForm: () => import('@/components/auth/RegisterForm.vue')
	}
}
</script>
